<template>
  <div>
    <div
      class="assembly-page-form-body-qr"
      @click="qrInit"
    >
      <QrCodeImage v-if="qrDestroyed"/>
      <p v-if="qrDestroyed">Нажмите, чтобы отсканировать QR-код изделия</p>
      <p v-if="qrDestroyed">Наклейка с QR-кодом находится на упаковке, непосредственно
        на самом приборе и в тех. паспорте</p>
      <!-- Показ камеры -->
      <div
        v-if="!qrDestroyed && cameraStatus"
        class="assembly-page-form-body-qr-stream"
      >
        <QrcodeStream
          @decode="onDecode"
          @init="onInit"
          :track="paintBoundingBox"
          v-if="!qrDestroyed"
        />
      </div>
    </div>
    <b-button
      v-if="!qrDestroyed && cameraStatus"
      @click="qrDestroyed = true"
      variant="primary"
      class="mt-3"
    >
      Закрыть камеру
    </b-button>
    <div
      v-if="qrDestroyed"
      class="text-center mb-4"
    >
    </div>
    <!-- Ошибка камеры -->
    <div
      v-else-if="!qrDestroyed && !cameraStatus"
      class="text-center"
    >
      <b-iconstack font-scale="7">
        <b-icon
          stacked
          icon="camera"
          variant="primary"
          scale="0.75"
        ></b-icon>
        <b-icon
          stacked
          icon="slash-circle"
          variant="danger"
        ></b-icon>
      </b-iconstack>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import QrCodeImage from '../assets/qr-code-scan.svg';
import { config } from '../config/index';

export default {
  name: 'QrReader',
  components: {
    QrCodeImage,
    QrcodeStream,
  },
  data() {
    return {
      isLoading: true,
      qrDestroyed: true,
      cameraStatus: true,
      serialNumber: {
        value: null,
        state: null,
        invalidFeedback: null,
      },
      statusAction: {
        ready: 'Нажмите для сканирования QR-кода',
        scanning: 'Поднесите устройство для сканирования',
        success: 'Вы успешно привязали устройство!',
      },
      cameraErrors: {
        NotAllowedError: 'Необходимо предоставить доступ к камере',
        NotFoundError: 'Отсутствует камера на этом устройстве',
        NotSupportedError: 'Требуется защищенное соединение https',
        NotReadableError: 'Камера уже используется',
        OverconstrainedError: 'Камера не подходит',
        StreamApiNotSupportedError: 'Stream API не поддерживается в этом браузере',
      },
    };
  },
  methods: {
    async onInit(promise) {
      this.isLoading = true;
      this.error = '';
      this.cameraStatus = true;
      this.status = this.statusAction.scanning;
      try {
        await promise;
      } catch (error) {
        this.cameraStatus = false;
        this.status = this.cameraErrors[error.name];
      }
      this.isLoading = false;
    },
    onDecode(decodedString) {
      this.error = '';

      let url = '';

      try {
        url = new URL(decodedString);
      } catch (e) {
        this.error = e;
        this.isLoading = false;
        return;
      }

      // Проверка расшифрованного серийного номера
      let serialNumber = '';
      if (url.searchParams.has('SN')) {
        serialNumber = url.searchParams.get('SN');
      } else if (url.searchParams.has('sn')) {
        serialNumber = url.searchParams.get('sn');
      } else if (url.searchParams.has('Sn')) {
        serialNumber = url.searchParams.get('Sn');
      } else if (url.searchParams.has('sN')) {
        serialNumber = url.searchParams.get('sN');
      } else {
        this.error = 'QR код не содержит серийный номер';
        this.serialNumber.state = false;
        this.qrDestroyed = true;
        this.isLoading = false;
        return;
      }

      /**
       * Проверка разрешенных хостов и наличия параметров
       */
      if (!config.allowDomainsQrScan.includes(url.hostname)) {
        this.error = 'Неверный QR код';
        this.serialNumber.state = false;
        this.qrDestroyed = true;
        this.isLoading = false;
        return;
      }

      this.serialNumber.value = serialNumber;
      this.serialNumber.state = true;

      // Таймаут отправки на сервер
      setTimeout(
        this.qrDecodeTimout,
        500,
      );
      this.$emit('serialNumber', this.serialNumber);
    },
    qrDecodeTimout() {
      this.qrDestroyed = true;
      this.isLoading = false;
      this.status = this.statusAction.ready;
    },
    qrInit() {
      this.isLoading = true;
      this.serialNumber.value = '';
      this.serialNumber.state = null;
      this.qrDestroyed = false;
      this.isLoading = false;
    },
    qrDestroy() {
      this.status = this.statusAction.ready;
      this.qrDestroyed = true;
    },
    paintBoundingBox(detectedCodes, ctx) {
      ctx.strokeStyle = '#4044C9';
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.moveTo(detectedCodes.topLeftCorner.x, detectedCodes.topLeftCorner.y);
      ctx.lineTo(detectedCodes.topRightCorner.x, detectedCodes.topRightCorner.y);
      ctx.lineTo(detectedCodes.bottomRightCorner.x, detectedCodes.bottomRightCorner.y);
      ctx.lineTo(detectedCodes.bottomLeftCorner.x, detectedCodes.bottomLeftCorner.y);
      ctx.lineTo(detectedCodes.topLeftCorner.x, detectedCodes.topLeftCorner.y);
      ctx.closePath();
      ctx.stroke();
    },
  },
};
</script>

<style lang="scss">
.assembly-page-form-body-qr {
  background-color: #F8F9FA;
  cursor: pointer;
  width: 100%;
  margin-top: 30px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  border: 1px solid #ADB5BD;
  border-radius: 2px;

  .assembly-page-form-body-qr-stream {
    width: 100%;
    height: 250px;
    margin: 0;
  }

  p {
    padding: 0 70px;
  }

  p, svg {
    margin: 0;
    transition: 0.3s;
  }

  p:last-child {
    font-size: 12px;
  }

  &:hover {
    p, svg {
      transform: scale(1.1);
    }
  }
}
</style>
